<template>
  <div class="columns is-vcentered is-centered pt-4">
    <div class="column is-4">
      <div
        class="box is-radiusless"
        v-bind:class="{ 'has-background-danger-light': error }"
      >
        <h1 class="title has-text-centered">{{ title }}</h1>
        <h2 class="subtitle has-text-centered">Login</h2>
        <form @submit.prevent="onSubmit">
          <div class="field mb-1">
            <input
              v-model="user.email"
              class="input"
              type="text"
              placeholder="Email"
            />
          </div>
          <div class="field">
            <input
              v-model="user.password"
              class="input"
              type="password"
              placeholder="Senha"
            />
          </div>
          <hr />

          <div
            class="
              is-flex is-justify-content-space-between is-align-items-center
            "
          >
            <button
              :disabled="!user.email || !user.password"
              class="button is-info is-radiusless"
              type="submit"
            >
              Login
            </button>
            <router-link to="/recover_password" class="is-size-7"
              >Esqueci minha senha</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      error: false,
      user: {},
    };
  },
  methods: {
    onSubmit() {
      this.$http
        .post("auth", this.user)
        .then((response) => {
          this.$store.commit("token", response.data.token);
          this.$router.push("terminals");
        })
        .catch(() => {
          this.error = true;
          setTimeout(() => (this.error = false), 800);
        });
    },
  },
  created() {
    this.$store.commit("token");
  },
};
</script>
